/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { trackingHandler } from '../service';
// import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
// import { strings } from '../utils/localizedStrings';
import { PageSpinner } from '../staticComponents/spinner';
import { myOrderMapping } from '../utils/entity_mapping';
import InvalidShipmentImage from './InvalidShipmentImage';
import { strings } from '../utils/localizedStrings';

const enterPrisePlaceholder = {
  superbottoms:
    'https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/assets/superbottoms/Superbottoms+Logo.png'
};
class MyOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: { orderId: '' },
      isFetching: true,
      isInvalidOrder: false,
      message: ''
    };
  }

  componentDidMount() {
    const { user, page, location, tracking } = this.props;
    const { routePrefix } = user;

    const { search, hash } = location;
    const searchParams = new URLSearchParams(search + hash);
    const query = {
      cpId: searchParams.get('cp_id'),
      waybill: searchParams.get('waybill'),
      orderId: searchParams.get('order_id'),
      security_key: searchParams.get('security_key') || searchParams.get('key'),
      courierRating: searchParams.get('courier_rating'),
      app_only: searchParams.get('app_only')
    };

    /**
     * If the subdomain is snitch and the order id contains a / then replace it with an empty string
     * This is done to handle the limechat case where the order id is passed as a query param and order value contains slash(/) in it.
     * CDA-1885 - Revert ticket for this change
     */
    if (user.subdomain === 'snitch' && query.orderId) {
      query.orderId = query.orderId.replace(/\//g, '');
    }
    if (query.orderId) {
      query.orderId = encodeURIComponent(query.orderId);
    }

    if (user.env !== 'development') {
      const { location } = this.props.history;
      ReactGA.set({ dimension1: user.enterprise });
      ReactGA.set({ dimension7: page.source || '' });
      ReactGA.set({
        dimension9: tracking.theme.enterprise_user_id
      });
      ReactGA.pageview(location.pathname + location.search);
    }
    this.props.fetchTrackingHandler(query).then(res => {
      if (res.meta && res.meta.success) {
        if ('shipments' in res.result && res.result.shipments.length > 0) {
          this.setState({
            order: {
              ...myOrderMapping(res.result, user),
              orderId: decodeURIComponent(query.orderId)
            },
            isFetching: false
          });
        } else {
          this.props.history.push(
            `/${routePrefix ? `${routePrefix}` : ''}?${
              query.app_only ? `&app_only=${query.app_only}` : ''
            }`
          );
        }
      } else {
        this.setState({
          isInvalidOrder: true,
          message:
            res?.meta?.message ??
            'We were unable to find the order you were looking for',
          isFetching: false
        });
        // toast.error(res.meta.message);
        // this.props.history.push(
        //   `/${routePrefix ? `${routePrefix}` : ''}?${
        //     query.app_only ? `&app_only=${query.app_only}` : ''
        //   }`
        // );
      }
    });
  }
  render() {
    const { user, tracking, location } = this.props;
    const { routePrefix } = user;
    const { udfKeys } = tracking.theme;
    const { isFetching, order } = this.state;
    const { search, hash } = location;
    const searchParams = new URLSearchParams(search + hash);
    const app_only = searchParams.get('app_only');
    const placeholder =
      (tracking.theme.meta_data &&
        tracking.theme.meta_data.image_placeholder) ||
      'https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/assets/img_placeholder.svg';

    let reverseOrders = order.shipments?.filter(item => item.config.is_rvp);
    let forwardOrders = order.shipments?.filter(item => !item.config.is_rvp);
    // Filtering out the shipments with status code 10 = Cancelled if shipments are more than 1
    const showCancelledShipments = udfKeys?.showCancelledShipments === 'true';
    if (!showCancelledShipments) {
      if (reverseOrders?.length > 1) {
        reverseOrders = reverseOrders.filter(
          item => item.latest_status?.clickpost_status_code !== 10
        );
      }
      if (forwardOrders?.length > 1) {
        forwardOrders = forwardOrders.filter(
          item => item.latest_status?.clickpost_status_code !== 10
        );
      }
    }

    const addDaysToDate = daysToAdd => {
      let date = new Date(order.orderDate.slice(0, 10));
      date.setDate(date.getDate() + daysToAdd);
      const options = { day: 'numeric', month: 'short', year: '2-digit' };
      return date.toLocaleDateString('en-GB', options);
    };

    const ShipmentDetail = shipment => (
      <div className="col-md-24 col-xs-24 mrg-btm-10">
        <div className="card my-order shopify pd-0">
          <div className="row md-flex md-flex-col mrg-0">
            <div className="header md-flex justify-between items-center mrg-btm-0">
              <span>
                Current Status:{' '}
                <span
                  className="mrg-btm-15 mrg-right-20"
                  style={{
                    color: shipment.latest_status.color
                  }}
                >
                  <b>{shipment.latest_status.text}</b>
                </span>
                {/* {shipment.config.is_rvp && (
                  <span className="tags">Return</span>
                )} */}
              </span>
              {!shipment.waybill && user.subdomain === 'wellversed' ? (
                <p
                  className={`p-4 rounded fs-16 bg-success-light text-black visible hidden-xs`}
                >
                  <span className="icon-info mrg-right-5 text-success fs-18" />
                  Shipment will be delivered between {addDaysToDate(2)} -
                  {addDaysToDate(4)}
                </p>
              ) : (
                <NavLink
                  className={`button text-center ${
                    shipment.waybill ? '' : 'disable-element'
                  } visible hidden-xs`}
                  to={`/${routePrefix ? `${routePrefix}` : ''}?waybill=${
                    shipment.waybill
                  }${app_only ? `&app_only=${app_only}` : ''}${
                    shipment?.config?.cp_id
                      ? `&cp_id=${shipment?.config?.cp_id}`
                      : ''
                  }`}
                >
                  {shipment.buttonName}
                </NavLink>
              )}
            </div>
            <div className="content col-md-24 col-xs-24 pd-20 xs-pd-15">
              {shipment?.skus?.length > 0
                ? shipment?.skus?.map(
                    (sku, idx) =>
                      (user.subdomain === 'mymuse' ? idx === 0 : true) && (
                        <div className="row md-flex product-item" key={idx}>
                          <div className="col-md-24 col-xs-24">
                            <div className="product-thumbnail">
                              {sku.images.length > 0 ? (
                                <img src={sku.images[0]} alt="Product" />
                              ) : (
                                <img
                                  src={
                                    enterPrisePlaceholder[user.subdomain] ||
                                    placeholder
                                  }
                                  alt="Product"
                                />
                              )}
                            </div>
                            <div className="product-detail">
                              <p>
                                <strong className="text-black fs-14">
                                  {sku.description}
                                </strong>
                              </p>
                              {tracking?.theme?.meta_data?.shopify_edd &&
                                sku?.edd?.min &&
                                sku?.edd?.max && (
                                  <p className="mrg-top-15 fs-14">
                                    <span className="text-gray display-inline-block xs-display-in-block">
                                      Expected Delivery Date Range:&nbsp;
                                    </span>
                                    <span className="text-green display-inline-block xs-display-in-block">
                                      {moment(sku.edd.min).format(
                                        'Do MMM YYYY'
                                      )}{' '}
                                      to{' '}
                                      {moment(sku.edd.max).format(
                                        'Do MMM YYYY'
                                      )}
                                    </span>
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      )
                  )
                : null}
              <div className="row md-flex hide visible-xs">
                <div className="col-md-24 col-xs-24">
                  {!shipment.waybill && user.subdomain === 'wellversed' ? (
                    <div className="d-flex p-4 rounded fs-16 bg-success-light text-black">
                      <p className="icon-info mrg-right-5 text-success fs-16" />
                      <p>
                        Shipment will be delivered between {addDaysToDate(2)} -
                        {addDaysToDate(4)}
                      </p>
                    </div>
                  ) : (
                    <NavLink
                      className={`button text-center ${
                        shipment.waybill ? '' : 'disable-element'
                      }`}
                      to={`/${routePrefix ? `${routePrefix}` : ''}?waybill=${
                        shipment.waybill
                      }${app_only ? `&app_only=${app_only}` : ''}`}
                    >
                      {shipment.buttonName}
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div
        className={`container-fluid pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10 main-container
          ${user.subdomain == '6thstreet' ? 'sixthstreet' : ''}
          ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}
          ${user.subdomain == '21kschool' ? 'twentyonekschool' : ''}
          ${
            user.subdomain == '1mg' || user.subdomain == '1mg-test'
              ? 'onemg'
              : user.subdomain
          }`}
      >
        {isFetching ? (
          <PageSpinner subdomain={user.subdomain} />
        ) : (
          <div className="row col-gutter-200">
            {this.state.isInvalidOrder ? (
              <div className="invalid-search col-md-16 col-md-offset-4 col-xs-24">
                <InvalidShipmentImage />
                <p>{this.state.message}</p>
              </div>
            ) : (
              <>
                <div className="col-md-24 col-xs-24">
                  <div className="row card my-order mrg-0">
                    <div className="col-md-4 col-xs-24 order-info bd-left-0 pd-left-0">
                      <span className="label">
                        {strings[this.props.user.lang].orderPlaced}
                      </span>
                      <span className="text">
                        {order.orderDate
                          ? moment(order.orderDate.slice(0, 10)).format(
                              'Do MMM YY'
                            )
                          : ''}
                      </span>
                    </div>
                    {/* <div className="col-md-4 col-xs-24 order-info">
                    <span className="label">Payment</span>
                    <span className="text">{order.payment}</span>
                  </div>
                  <div className="col-md-4 col-xs-24 order-info">
                    <span className="label">Total Amount</span>
                    <span className="text">{order.currencySymbol}{order.totalAmount}</span>
                  </div>
                  <div className="col-md-4 col-xs-24 order-info">
                    <span className="label">Ship to</span>
                    <span className="text">{order.customer.name}</span>
                  </div> */}
                    <div className="col-md-16 col-xs-24 order-info">
                      <span className="label">
                        {strings[this.props.user.lang].orderId}
                      </span>
                      <span className="text">{order.orderId}</span>
                    </div>
                  </div>
                </div>
                {user.subdomain === 'printstop' &&
                  order?.shipments?.map(shipment => (
                    <div
                      key={shipment.waybill}
                      className="col-md-24 col-xs-24 pd-top-15"
                    >
                      <div className="card my-order">
                        <div className="row md-flex">
                          <div
                            className={`${
                              user.subdomain === 'printstop'
                                ? 'col-md-8'
                                : 'col-md-17'
                            } col-xs-24 md-vertical-align`}
                          >
                            <div className="product-item xs-mrg-btm-15">
                              <p
                                className="mrg-btm-15"
                                style={{ color: shipment.latest_status.color }}
                              >
                                <b>{shipment.latest_status.text}</b>
                              </p>
                              {shipment?.skus?.length > 0
                                ? shipment?.skus?.map((sku, idx) => (
                                    <div className="row mrg-top-10" key={idx}>
                                      <div className="product-thumbnail">
                                        {sku.images.length > 0 ? (
                                          <img
                                            src={sku.images[0]}
                                            alt="Product"
                                          />
                                        ) : (
                                          <img
                                            src={
                                              enterPrisePlaceholder[
                                                user.subdomain
                                              ] || placeholder
                                            }
                                            alt="Product"
                                          />
                                        )}
                                      </div>
                                      <div className="product-detail">
                                        {sku.description}
                                      </div>
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                          {user.subdomain === 'printstop' && (
                            <>
                              <div className="col-md-5 xs-mrg-btm-10  col-xs-12">
                                <div>
                                  <strong className="shipment-label">
                                    Reference Number:
                                  </strong>
                                </div>
                                <div className="md-vertical-align h-100">
                                  <strong className="text-black">
                                    {shipment?.reference_number}
                                  </strong>
                                </div>
                              </div>

                              <div className="col-md-4 col-xs-12 xs-mrg-btm-10">
                                <div>
                                  <strong className="shipment-label">
                                    Status
                                  </strong>
                                </div>
                                <div className="md-vertical-align h-100">
                                  <span className="text-black">
                                    {shipment?.latest_status?.text}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            className={
                              'col-md-7 col-xs-24 md-vertical-align md-flex-end'
                            }
                          >
                            <NavLink
                              className={`button text-center ${
                                shipment.waybill ? '' : 'disable-element'
                              }`}
                              to={`/${
                                routePrefix ? `${routePrefix}` : ''
                              }?waybill=${shipment.waybill}${
                                app_only ? `&app_only=${app_only}` : ''
                              }${
                                shipment?.config?.cp_id
                                  ? `&cp_id=${shipment?.config?.cp_id}`
                                  : ''
                              }`}
                            >
                              {shipment.buttonName}
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div
                  className={`fw-600 fs-20 col-md-24 col-xs-24 mrg-top-30 mrg-btm-30 text-left ${
                    reverseOrders?.length > 0 ? 'show' : 'hide'
                  }`}
                >
                  Track your Returns
                </div>
                {user.subdomain !== 'printstop' &&
                  reverseOrders.map(shipment => (
                    <ShipmentDetail key={shipment.waybill} {...shipment} />
                  ))}
                <div className="fw-600 fs-20 col-md-24 col-xs-24 mrg-top-30 mrg-btm-30 text-left">
                  Track your Deliveries
                </div>
                {user.subdomain !== 'printstop' &&
                  forwardOrders.map(shipment => (
                    <ShipmentDetail key={shipment.waybill} {...shipment} />
                  ))}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

MyOrder.propTypes = {
  user: PropTypes.object,
  page: PropTypes.object,
  tracking: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  fetchTrackingHandler: PropTypes.func
};

MyOrder.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  page: state.page
});

const mapDispatchToProps = dispatch => ({
  fetchTrackingHandler: query => dispatch(trackingHandler(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyOrder)
);
